import { above, media } from 'utils/mediaqueries';

import AspectWrapper from 'components/wrappers/AspectWrapper';
import Heading from 'components/text/Heading';
import Image from 'components/Image';
import Link from 'components/base/Link';
import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'libs/styled';

const Wrapper = styled('div', {
    shouldForwardProp: prop =>
        ['columnStyles', 'backgroundLandscape', 'backgroundPortrait', 'mediaQuery', 'size'].indexOf(prop) === -1,
})`
    width: 100%;
    height: 100%;
`;

const StyledHeading = styled(Heading)`
    margin-bottom: 12px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    line-height: initial;

    ${media.isIE} {
        max-height: 72px; /* height of three rows in mobile */

        ${above.md} {
            max-height: 96px; /* height of three rows in desktop */
        }
    }

    ${above.md} {
        margin-bottom: 24px;
    }
`;

const ImageWrapper = styled('div')`
    position: relative;
`;

const CategoryAndDateWrapper = styled('div')`
    display: flex;
    justify-content: space-between;
    margin-top: 12px;

    ${above.md} {
        margin-top: 16px;
    }
`;

const StyledLink = styled(Link)`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
`;

const ArticleCard = ({
    category = '',
    categoryAndDateStyles = {},
    date = '',
    heading,
    image,
    imageStyles = {},
    ratio = 3 / 4,
    to = '',
    ...rest
}) => (
    <Wrapper {...rest}>
        <StyledLink to={to}>
            {heading && <StyledHeading>{heading}</StyledHeading>}
            <ImageWrapper>
                <AspectWrapper ratio={ratio} {...imageStyles}>
                    <Image
                        alt={heading}
                        height="100%"
                        objectFit="cover"
                        sizes={['100vw', '33.33vw', null, null, '640px']}
                        src={{ url: image.url, width: [375, 768, 338, 476, 640] }}
                    />
                </AspectWrapper>
            </ImageWrapper>
            {(category || date) && (
                <CategoryAndDateWrapper width="100%" {...categoryAndDateStyles}>
                    {category && (
                        <Paragraph size="md" width="50%">
                            {category}
                        </Paragraph>
                    )}
                    {date && (
                        <Paragraph size="md" width="50%">
                            {date}
                        </Paragraph>
                    )}
                </CategoryAndDateWrapper>
            )}
        </StyledLink>
    </Wrapper>
);

ArticleCard.propTypes = {
    category: PropTypes.string,
    categoryAndDateStyles: PropTypes.object,
    date: PropTypes.string,
    heading: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
    imageStyles: PropTypes.object,
    ratio: PropTypes.number,
    to: PropTypes.string,
};

export default ArticleCard;
